import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SaveChangesDialogService } from '@ih/dialogs';
import { PrivateAppGuardService } from '@ih/services';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'advancedOptions',
    pathMatch: 'full'
  },
  {
    path: 'advancedOptions',
    loadComponent: () =>
      import('./advanced-options/advanced-options.component').then((m) => m.AdvancedOptionsComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  { path: 'channels', redirectTo: '/channels' },
  {
    path: 'contactUs',
    loadComponent: () =>
      import('./contact-us-editor/contact-us-editor.component').then((m) => m.ContactUsEditorComponent)
  },
  {
    path: 'customCss',
    redirectTo: '/customCode'
  },
  {
    path: 'customDomains',
    loadComponent: () => import('./custom-domains/custom-domains.component').then((m) => m.CustomDomainsComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'trackingCode',
    redirectTo: '/customCode'
  },
  {
    path: 'customCode',
    loadComponent: () => import('./custom-code/custom-code.component').then((m) => m.CustomCodeComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'lookAndFeel',
    loadComponent: () => import('./look-and-feel/look-and-feel.component').then((m) => m.LookAndFeelComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'members',
    children: [
      {
        path: '',
        loadComponent: () => import('./members/members.component').then((m) => m.MembersComponent),
        canActivate: [PrivateAppGuardService, SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: ':memberId',
        loadComponent: () => import('./member-editor/member-editor.component').then((m) => m.MemberEditorComponent),
        canActivate: [PrivateAppGuardService, SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      }
    ]
  },
  {
    path: 'menu',
    loadComponent: () => import('./menu-editor-page/menu-editor-page.component').then((m) => m.MenuEditorPageComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'pages',
    loadComponent: () => import('@ih/post').then((m) => m.PostListComponent),
    data: {
      title: 'Pages',
      singular: 'page',
      landingPage: true,
      editorIconPath:
        'M20 5L20 19L4 19L4 5H20M20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3M18 15H6V17H18V15M10 7H6V13H10V7M12 9H18V7H12V9M18 11H12V13H18V11Z'
    },
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'payments',
    loadComponent: () => import('./payment-list/payment-list.component').then((m) => m.PaymentListComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'subscriptions',
    loadComponent: () =>
      import('./subscription-list/subscription-list.component').then((m) => m.SubscriptionListComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'posts',
    loadComponent: () => import('@ih/post').then((m) => m.PostListComponent),
    data: {
      title: 'Posts',
      singular: 'post',
      landingPage: false,
      editorIconPath: 'M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z'
    },
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'postTypes',
    loadComponent: () => import('@ih/post').then((m) => m.PostTypesComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'products',
    loadComponent: () => import('./product-admin/product-admin.component').then((m) => m.ProductAdminComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'sliders',
    loadComponent: () => import('@ih/slider').then((m) => m.SliderListComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'welcomeMessage',
    loadComponent: () => import('./welcome-message/welcome-message.component').then((m) => m.WelcomeMessageComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'merchantAccounts',
    loadComponent: () =>
      import('./merchant-accounts/merchant-accounts.component').then((m) => m.MerchantAccountsComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService]
  },
  {
    path: 'urlRedirects',
    loadComponent: () => import('./url-redirects/url-redirects.component').then((m) => m.UrlRedirectsComponent),
    canActivate: [PrivateAppGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
