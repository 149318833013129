import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type FeedbackDialogComponent } from './feedback-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(): Promise<MatDialogRef<FeedbackDialogComponent>> {
    const feedbackDialogComponent = await import('./feedback-dialog.component').then((m) => m.FeedbackDialogComponent);

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(feedbackDialogComponent, {
      panelClass: ['feedback-dialog', 'basic-dialog', 'fullscreen-lt-sm'],
      id: 'feedbackDialog',
      width: undefined,
      height: undefined,
      maxWidth: undefined,
      maxHeight: undefined
    });
  }
}
